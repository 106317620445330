import React from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';

import './slider-bullets.less';

export function SliderBullets({
  activeIndex,
  length,
  slideTo,
  style,
  bulletColor,
}) {
  const classNames = useClassName('SliderBullets');
  return (
    <div className={classNames('container')} style={style}>
      {[...Array(length).keys()].map((index) => (
        <div
          key={index}
          onClick={() => slideTo(index)}
          {...(Boolean(bulletColor) && { style: { background: bulletColor } })}
          {...(activeIndex === index && {
            className: classNames('active'),
          })}></div>
      ))}
    </div>
  );
}

SliderBullets.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  slideTo: PropTypes.func.isRequired,
  style: PropTypes.object,
  bulletColor: PropTypes.string,
};

SliderBullets.defaultProps = {
  style: {},
  bulletColor: '',
};
