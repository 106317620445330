import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumb from '../Breadcrumb';

const getLinks = (type, event) => {
  const links = [{ url: '/events/category', title: 'Events' }];

  if (type.slug && type.name) {
    links.push({ url: `/venue/${type.slug}`, title: type.name });
  }

  if (event?.title) {
    links.push({ url: window.location.pathname, title: event.title });
  }

  return links;
};

const EventDetailsBreadcrumb = ({ type, event }) => {
  return <Breadcrumb links={getLinks(type, event)} />;
};

EventDetailsBreadcrumb.propTypes = {
  type: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  event: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default EventDetailsBreadcrumb;
